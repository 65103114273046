import {loanPageApi} from '@/services/api/requestApi';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePaginationPage} from "@/hooks/usePaginationPage";

export const useLoansOrderStateHooks = () => {
    const [current, setCurrent] = useState('1');
    const {t} = useTranslation();


    const options = [
        {
            text: t('借贷'),
            value: '1',
        },
        {
            text: t('还款'),
            value: '2',
        },
    ];

    const [params, setParams] = useState({
        pageNo: 1,
        pageSize: 10,
    });
    const loanPageListLog = usePaginationPage({
        fn: loanPageApi,
        params,
        setParams
    });

    // 监听筛选数据
    const sortDataList = useMemo(() => {
        if (loanPageListLog?.list?.length !== 0) {
            if (current === '1') {
                return loanPageListLog?.list?.filter((item: any) => item?.type === 1);
            } else {
                return loanPageListLog?.list?.filter((item: any) => item?.type !== 1);
            }
        }
        return  []
    }, [loanPageListLog?.list, current]);

    useEffect(() => {
        setParams((pev)=>({...pev, type: current}))
    },[current])
    
    return {
        options,
        current,
        setCurrent,
        loanPageListLog,
        sortDataList,
    };
};

// 定义useLoansOrderStateHooks函数返回值的类型
export type useLoansOrderStateHooks = ReturnType<
    typeof useLoansOrderStateHooks
>;
