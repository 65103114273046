import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import successIcon from '@/assets/loans/check-circle-filled.png';
import closeIcon from '@/assets/loans/close-circle-filled.png';
import filledIcon from '@/assets/loans/time-filled.png';
import { BaseFlex } from '@/components/base/baseFlex';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTabs from '@/components/base/baseTabs';
import BaseTopNav from '@/components/base/baseTopNav';
import { useLoansOrderStateHooks } from '@/pages/funds/loansOrder/useStateHooks';
import RenderUtil from '@/utils/RenderUtil';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LoansOrderView() {
  const { t } = useTranslation();

  const { options, setCurrent, current, loanPageListLog, sortDataList } =
    useLoansOrderStateHooks();

  const statusAudit: any = {
    null: <span className="ml-1 text-auxiliaryTextColor">{t('待审核')}</span>,
    0: <span className="ml-1 text-auxiliaryTextColor">{t('待审核')}</span>,
    1: <span className="ml-1 text-[#00CD88]">{t('成功')}</span>,
    '-1': <span className="ml-1 text-errorColor ">{t('失败')}</span>,
  };

  const imageShow: any = {
    null: filledIcon,
    0: filledIcon,
    1: successIcon,
    '-1': closeIcon,
  };
  return (
    <div>
      <BaseTopNav title={t('借贷')} rightNode={
        loanPageListLog?.loading && (
          <div className="flex items-center justify-center w-fit h-full mr-4">
            <SvgSpinnersBarsRotateFade />
          </div>
        )
      }/>
      <div className="px-4">
        <BaseTabs
          value={current}
          options={options as any}
          onChange={(value) => {
            setCurrent(value);
          }}
        />
      </div>
      <div className="px-4">
        <PullToRefresh
            {...loanPageListLog}
          loadMore={loanPageListLog?.loadMore}
          total={loanPageListLog.total}
          list={sortDataList as any}
          loading={loanPageListLog?.loading}
          getList={loanPageListLog?.getList}
        >
          {sortDataList?.map((item: any) => (
            <BaseFlex
              justify="between"
              align="center"
              flex={1}
              className="my-4 border-b-1 border-b-borderColor"
            >
              <BaseFlex
                justify="start"
                align="center"
                vertical
                flex={1}
                className="mb-2"
              >
                <div className="w-full mb-[4px]">
                  <span className="font-[600] text-[16px]">{+item?.type === 1 ? t('借贷') : t('还款')}</span>
                  <span className="font-[600] text-[16px] ml-2">
                    {RenderUtil.FormatAmount(item?.cost)}
                  </span>
                </div>
                <div className="w-full text-[#4E5969] text-xs">
                  {RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}
                </div>
              </BaseFlex>
              <BaseFlex justify="end" align="center" flex={1}>
                <img
                  className="w-[16px] h-[16px]"
                  src={imageShow[item?.status]}
                />
                {statusAudit[item?.status]}
              </BaseFlex>
            </BaseFlex>
          ))}
        </PullToRefresh>
      </div>
    </div>
  );
}
